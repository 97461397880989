import "./App.css";
import Icon from "./static/icon.png";

const App = () => {
  return (
    <div className="w-full bg-zinc-200 h-full font-medium text-gray-700 text-center p-0 m-0">
      <h1>Welcome to Promedio!</h1>
      <div className="flex justify-center">
        <img alt="Promedio logo" className="w-52" src={Icon} />
      </div>

      <h1>Coming to you...maybe?</h1>
      <h3>Sorry for the long wait.</h3>

      <p className="pt-5 pb-2">2024, potentially 2025</p>
    </div>
  );
};

export default App;
